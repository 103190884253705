<template>
    <section class="features8 cid-rIh4wI9O0B blog mt-0 pt-0 " id="features8-2n" style="background: #eee">
        <div class="container-fluid no-glutters">
            <div class="row">
                <div v-if="error">Something went wrong...</div>
                <div v-if="loading">Loading...</div>
                <div class="col-md-4" v-else v-for="blog in result.getAllBlog" :key="blog.id">
                    <div class="blog-img">
                        <a href="https://www.tulsulycsokkentomutet.hu/blog/archivum/2020/01">
                            <div class="date">
                            </div>
                        </a>
                        <a href="https://www.tulsulycsokkentomutet.hu/blog/hajhullas">
                            <img class="w-100 h-auto" :src="'https://www.tulsulycsokkentomutet.hu/storage/' + blog.imgurl"
                                :title="blog.title" :alt="blog.title">
                        </a>
                    </div>
                    <div class="p-2 w-100">
                        <a href="https://www.tulsulycsokkentomutet.hu/kategoria/foorvos-valaszol"
                            class="btn btn-bgr btn-secondary text-uppercase">
                            Főorvos válaszol
                        </a>
                        <h5><a href="https://www.tulsulycsokkentomutet.hu/blog/hajhullas">Hajhullás</a>
                        </h5>
                        <p>A hajszál kihullása része a normális hajváltásnak, a hajszál kihullik, helyére új
                            szál lép. Amennyiben több hajszál pusztul el, mint képződik, a haj ritkul.</p>
                        <div class="btn-bar">
                            <a href="https://www.tulsulycsokkentomutet.hu/blog/hajhullas" class="px-btn-arrow">
                                <span>Tovább a cikkhez</span>
                                <i class="arrow"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <a href="https://www.tulsulycsokkentomutet.hu/blog">
        <center>
            <button class="mb-5 btn btn-success">Összes blog bejegyzés</button>
        </center>
    </a>
</template>
<script>
import gql from 'graphql-tag'
import { useQuery } from '@vue/apollo-composable'
const BLOG_QUERY = gql`
 { getAllBlog {
    id
    maintitle
    url
    title
    imgurl
    category
    content
    lead
    date
  }}
`
export default {
    setup() {
        const { result, loading, error } = useQuery(BLOG_QUERY);
        return {
            result,
            loading,
            error
        }
    }
}
</script>